import "./MenuButton.scss";

function MenuButton(props: { icon: string, text: string, onClick?: () => void}) {
  return (
    <div>
      <div className="menu-button" onClick={props.onClick}>
        <div className="menu-button-icon">
          <i className={props.icon} />
        </div>
        <span className="menu-button-text">{props.text}</span>
        <i className={"fa fa-angle-right"} />
      </div>
      <hr />
    </div>
  );
}

export default MenuButton;
