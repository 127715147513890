import "./ListScreen.scss";
import { useEffect, useState } from "react";
import appService from "../../services/appService";
import Consultant from "../../models/Consultant";
import AppHeader from "../commons/AppHeader";
import { toast } from "react-toastify";
import ConsultantList from "./ConsultantList";
import ptBR from "../../languages/pt-BR";
import Div100vh from "react-div-100vh";

function ConsultantsScreen() {
  const [consultants, setConsultants] = useState<Consultant[] | null>(null);

  async function getConsultantsList() {
    try{
      const list = await appService.getConsultants();
      setConsultants(list);
    }
    catch(e: any){
      toast.error(e);
    }
  }

  useEffect(() => {
    getConsultantsList();
  }, []);

  return (
    <Div100vh className="list-screen">
      <AppHeader />
      <h2>{ptBR.menu.consultants}</h2>
      <ConsultantList consultants={consultants}/>
    </Div100vh>
  );
}

export default ConsultantsScreen;
