import axios from "axios";
import Product from "../models/Product";
import Consultant from "../models/Consultant";
import BffResponse from "../models/BffResponse";

const API_URL = process.env.REACT_APP_PROD_ADMIN;
const CLASSIFIER_URL = process.env.REACT_APP_PROD_CLASSIFIER;
const CLASSIFIER_USERNAME = process.env.REACT_APP_API_USER ?? "";
const CLASSIFIER_PASSWORD = process.env.REACT_APP_API_PWD ?? "";
const REQUEST_TIMEOUT = 100000;

async function getProducts() {
  const config = {
    timeout: REQUEST_TIMEOUT,
  };

  const response = await axios.get(API_URL + "/products", config);

  if (response.status === 204) {
    return [];
  }
  if (response.data.status === "success") {
    const list = response.data.payload.map((p: any) => ({
      product_name: p.product_name,
      product_description: p.product_desc,
      product_image_url: p.imagem_url,
      product_link: p.product_link,
    }));
    return list as Product[];
  } else {
    throw response.data;
  }
}

async function getConsultants() {
  const config = {
    timeout: REQUEST_TIMEOUT,
  };

  const response = await axios.get(API_URL + "/atendents-consultants", config);

  if (response.status === 204) {
    return [];
  }
  if (response.data.status === "success") {
    return response.data.payload as Consultant[];
  } else {
    throw response;
  }
}

async function classifyImage(file: File) {
  const config = {
    timeout: REQUEST_TIMEOUT,
    auth: {
      username: CLASSIFIER_USERNAME,
      password: CLASSIFIER_PASSWORD,
    },
    headers: { "Content-Type": file.type },
  };
  const response = await axios.post(CLASSIFIER_URL + "/upload", file, config);
  if (response.data.status === "ok") {
    return response;
  } else {
    throw response;
  }
}

async function downloadImage(request_token: string) {
  const response = await axios.get(
    CLASSIFIER_URL + `/download?request_token=${request_token}`,
    {
      responseType: "blob",
      timeout: REQUEST_TIMEOUT,
      auth: {
        username: CLASSIFIER_USERNAME,
        password: CLASSIFIER_PASSWORD,
      },
    }
  );
  return response;
}

async function bff(plague_name: string, request_token: string) {
  const response = await axios.get(API_URL + `/bff/${plague_name}/${request_token}`, {
    timeout: REQUEST_TIMEOUT,
    auth: {
      username: CLASSIFIER_USERNAME,
      password: CLASSIFIER_PASSWORD,
    },
  });

  if (response.status === 204) {
    throw response;
  }
  if (response.data.status === "success") {
    return response.data as BffResponse;
  } else {
    throw response;
  }
}

const appService = {
  getProducts,
  getConsultants,
  classifyImage,
  downloadImage,
  bff,
};

export default appService;
