import { useNavigate } from "react-router-dom";
import MainButton from "../commons/MainButton";
import logo from "../../logo.png";
import "./Home.scss";
import { useRef } from "react";
import MenuNav from "../commons/MenuNav";
import ptBR from "../../languages/pt-BR";
import Div100vh from "react-div-100vh";
import { toast } from "react-toastify";

function Home() {
  const navigate = useNavigate();
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  function selectImg(event: any) {
    navigate("/camera", { state: { file: event.target.files[0] } });
  }

  return (
    <Div100vh className="home-screen">
      <div className="home-logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="home-content">
        <br />
        <span>{ptBR.menu.displayText}:</span>
        <br />
        <hr />
        <MenuNav />
      </div>
      <MainButton onClick={() => hiddenFileInput.current?.click()}>
        <i className={"fa fa-camera main-button-icon"} />
      </MainButton>
      <input
        type="file"
        onChange={selectImg}
        style={{ display: "none" }}
        ref={hiddenFileInput}
        accept=".jpg,.jpeg,.png"
      />
    </Div100vh>
  );
}

export default Home;
