import MainButton from "../commons/MainButton";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./PlagueNotFoundScreen.scss";
import ptBR from "../../languages/pt-BR";
import AppHeader from "../commons/AppHeader";
import Div100vh from "react-div-100vh";

function PlagueNotFoundScreen() {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  function selectImg(event: any) {
    navigate("/camera", { state: { file: event.target.files[0] } });
  }

  return (
    <Div100vh className="not-found-screen">
      <AppHeader />
      <h3 className="info">
        {ptBR.camera.classifyImageError} <br />
        {ptBR.plagueNotFound.tryAgain}
      </h3>
      <MainButton onClick={() => hiddenFileInput.current?.click()}>
        <i className={"fa fa-camera main-button-icon"} />
      </MainButton>
      <input
        type="file"
        onChange={selectImg}
        style={{ display: "none" }}
        ref={hiddenFileInput}
      />
    </Div100vh>
  );
}

export default PlagueNotFoundScreen;
