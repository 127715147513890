const ptBR = {
    menu:{
        identifyPlague: "Identificador de pragas",
        consultants: "Consultores",
        products: "Produtos",
        displayText: "Olá! Escolha uma das opções abaixo"
    },
    list:{
        emptyList: "Nenhum item para exibir",
        loadingList: "Carregando lista"
    },
    camera:{
        classifyImagePending: "Analisando praga...",
        classifyImageSuccess: "Praga identificada!",
        classifyImageError: "Praga não identificada :(",
        sendImage: "Enviar para análise"
    },
    classifier:{
        plagueDescription: "Descrição da praga",
        loadingInfo: "Carregando informações da praga...",
        notFound: "Nenhuma praga encontrada :(",
        error: "Erro ao carregar informações da praga. Tente novamente mais tarde."
    },
    plagueNotFound:{
        tryAgain: "Por favor, tente novamente"
    },
}

export default ptBR;