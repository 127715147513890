import ListItem from "../commons/ListItem";
import "./ListScreen.scss";
import { useEffect, useState } from "react";
import appService from "../../services/appService";
import Product from "../../models/Product";
import AppHeader from "../commons/AppHeader";
import ptBR from "../../languages/pt-BR";
import ProductList from "./ProductList";
import Div100vh from "react-div-100vh";

function ProductsScreen() {
  const [products, setProducts] = useState<Product[] | null>(null);

  async function getProductsList() {
    try{
      const list = await appService.getProducts();
      setProducts(list);
    }
    catch(e){
      setProducts(null)
    }
  }

  useEffect(() => {
    getProductsList();
  }, []);

  return (
    <Div100vh className="list-screen">
      <AppHeader />
      <h2>{ptBR.menu.products}</h2>
      <ProductList products={products} expanded/>
    </Div100vh>
  );
}

export default ProductsScreen;
