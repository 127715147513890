import logo from "../../logo.png";
import "./AppHeader.scss";
import { slide as Menu } from "react-burger-menu";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MenuNav from "./MenuNav";

function AppHeader() {
  const navigate = useNavigate();
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  return (
    <div className="app-header">
      <div className="burguer-menu">
        <Menu isOpen={menuIsOpen} onClose={() => setMenuIsOpen(false)} onOpen={() => setMenuIsOpen(true)}>
          <i id="burguer-close-button" className={"fa fa-times cancel-cross"} onClick={() => setMenuIsOpen(false)}/>
          <MenuNav />
        </Menu>
        <i id="burguer-button" className={"fa fa-bars cancel-cross"} onClick={() => setMenuIsOpen(!menuIsOpen)}/>
      </div>
      <div className="home-logo">
        <img src={logo} alt="logo" onClick={() => navigate("/")} />
      </div>
    </div>
  );
}

export default AppHeader;
