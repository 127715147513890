import { useEffect, useState } from "react";
import "./ListItem.scss";
import { Link } from "react-router-dom";

type info = {
  icon: string;
  info: string;
};

function ListItem(props: {
  name: string;
  info?: info[];
  description: string;
  imageUrl: string;
  link: string;
  expanded?: boolean;
}) {
  const [toggle, setToggle] = useState(props.expanded);

  return (
    <Link
      className="list-item"
      to={props.link}
      style={{ textDecoration: "none" }}
      target="_blank"
      key={props.name}
      onClick={(event) => {
        if(!props.link || props.link === "") event.preventDefault();
      }}
    >
      {props.imageUrl && (
        <div className="item-img">
          <img src={props.imageUrl} alt={props.name} />
        </div>
      )}
      <div className="list-item-content">
        <h3>
          {props.name}
          {props.description && (
            <i
              className={`fa ${toggle ? "fa-angle-up" : "fa-angle-down"}`}
              style={{ cursor: "pointer" }}
              onClick={(event) => {
                event.preventDefault();
                setToggle((prev) => {
                  return !prev;
                });
              }}
            ></i>
          )}
        </h3>
        {props.info &&
          props.info.map((info) => (
            <div className="list-item-content-info" key={info.info}>
              <i className={info.icon + " list-item-icon"} />
              <a href={`tel:${info.info}`} style={{textDecoration: "none"}}>{info.info}</a>
            </div>
          ))}
        <div
          className="hidden-content"
          style={{
            height: toggle ? "fit-content" : "0px",
          }}
        >
          <p className="item-description">{props.description}</p>
        </div>
      </div>
    </Link>
  );
}

export default ListItem;
