import ptBR from "../../languages/pt-BR";
import ListItem from "../commons/ListItem";
import Product from "../../models/Product";
import "./ListScreen.scss";

function ProductList(props: { products: Product[] | null, className?: string, expanded?: boolean }) {
  return (
    <div className={props.className}>
      {props.products ? (
        props.products.length > 0 ? (
          props.products.map((product: Product) => (
            <ListItem
              name={product.product_name}
              description={product.product_description}
              imageUrl={product.product_image_url}
              link={product.product_link}
              expanded={props.expanded}
            />
          ))
        ) : (
          <div>{ptBR.list.emptyList}</div>
        )
      ) : (
        <div>{ptBR.list.loadingList}...</div>
      )}
    </div>
  );
}

export default ProductList;
