import "./MainButton.scss";

function MainButton(props: { children: React.ReactNode; onClick: () => void, disable?: boolean }) {
  return (
    <div className={`main-button ${props.disable && "disable"}`} onClick={() => {if(!props.disable) props.onClick()}}>
      <div className="main-button-icon">{props.children}</div>
    </div>
  );
}

export default MainButton;
