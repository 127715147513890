import "./App.css";
import Home from "./components/home/Home";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import CameraScreen from "./components/camera/CameraScreen";
import ClassifierScreen from "./components/classifier/ClassifierScreen";
import ConsultantsScreen from "./components/list/ConsultantsScreen";
import ProductsScreen from "./components/list/ProductsScreen";
import PlagueNotFoundScreen from "./components/plagueNotFound/PlagueNotFoundScreen";
import { Flip, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React from "react";

function App() {
  return (
    <React.Fragment>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Navigate to="/" replace />} />
            <Route path="/products" element={<ProductsScreen />}></Route>
            <Route path="/consultants" element={<ConsultantsScreen />}></Route>
            <Route path="/camera" element={<CameraScreen />}></Route>
            <Route path="/classifier" element={<ClassifierScreen />}></Route>
            <Route path="/notFound" element={<PlagueNotFoundScreen />}></Route>
          </Routes>
        </BrowserRouter>
      </div>
      <ToastContainer
        position="top-center"
        limit={1}
        transition={Flip}
        autoClose={2500}
      />
    </React.Fragment>
  );
}

export default App;
