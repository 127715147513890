import { useLocation, useNavigate } from "react-router-dom";
import "./ClassifierScreen.scss";
import { useEffect, useState } from "react";
import appService from "../../services/appService";
import AppHeader from "../commons/AppHeader";
import BffResponse from "../../models/BffResponse";
import React from "react";
import ptBR from "../../languages/pt-BR";
import ConsultantList from "../list/ConsultantList";
import ProductList from "../list/ProductList";
import { toast } from "react-toastify";

function ClassifierScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const [img, setImg] = useState<any | null>(null);
  const [plagueInfo, setPlagueInfo] = useState<BffResponse[] | null>(null);
  const [hasError, setHasError] = useState<boolean>(false);

  async function getPlagueInfo() {
    try {
      const response = await appService.downloadImage(
        location.state.request_token
      );
      setImg(URL.createObjectURL(response.data));
    } catch (e: any) {
      toast.error(ptBR.classifier.error);
      setHasError(true);
      navigate("/");
    }

    try {
      var plagues: BffResponse[] = [];
      for (var index in location.state.predict) {
        const plague_name = Object.keys(location.state.predict[index])[0];
        const response = await appService.bff(plague_name, location.state.request_token);
        plagues.push(response);
      }
      setPlagueInfo(plagues);
    } catch (e: any) {
      setHasError(true);
      toast.error(ptBR.classifier.error);
    }
  }

  useEffect(() => {
    if (
      !location.state ||
      !location.state.request_token ||
      !location.state.predict
    )
      navigate("/");
    getPlagueInfo();
  }, []);

  return (
    <div className="classifier-screen">
      <AppHeader />
      {plagueInfo ? (
        <React.Fragment>
          {plagueInfo &&
            (plagueInfo.length > 0 ? (
              plagueInfo.map((plague, index) => (
                <div className="plague-info" key={index}>
                  {img && (
                    <img src={img} alt="screenshot" className="plague-img" />
                  )}
                  <h2>{plague.payload.plague_name}</h2>
                  <hr />
                  <h4>{ptBR.classifier.plagueDescription}</h4>
                  <p className="plague-description">
                    {plague.payload.plague_description}
                  </p>
                  <hr />
                  <h4>{ptBR.menu.consultants}</h4>
                  <ConsultantList
                    consultants={plague.payload.consultants}
                    expanded
                  />
                  <hr />
                  <h4>{ptBR.menu.products}</h4>
                  <ProductList
                    products={plague.payload.products}
                    className="product-list"
                    expanded
                  />
                  {plagueInfo[index + 1] && (
                    <React.Fragment>
                      <hr />
                      <hr />
                    </React.Fragment>
                  )}
                </div>
              ))
            ) : (
              <div>
                {img && (
                  <img src={img} alt="screenshot" className="plague-img" />
                )}
                {ptBR.classifier.notFound}
              </div>
            ))}
        </React.Fragment>
      ) : (
        <div>
          {img && <img src={img} alt="screenshot" className="plague-img" />}
          {hasError ? (
            <div>{ptBR.classifier.error}</div>
          ) : (
            <div>{ptBR.classifier.loadingInfo}</div>
          )}
        </div>
      )}
    </div>
  );
}

export default ClassifierScreen;
