import ptBR from "../../languages/pt-BR";
import Consultant from "../../models/Consultant";
import ListItem from "../commons/ListItem";
import "./ListScreen.scss";

function ConsultantList(props: { consultants?: Consultant[] | null, expanded?: boolean }) {
  return (
    <div>
      {props.consultants ? (
        props.consultants.length > 0 ? (
          props.consultants.map((consultant: Consultant) => {
            var info = [];
            if (consultant.atendents_consultants_email)
              info.push({
                info: consultant.atendents_consultants_email,
                icon: "fa fa-envelope",
              });
            if (consultant.atendents_consultants_cellphone)
              info.push({
                info: consultant.atendents_consultants_cellphone,
                icon: "fa-brands fa-whatsapp",
              });
            if (consultant.atendents_consultants_phone)
              info.push({
                info: consultant.atendents_consultants_phone,
                icon: "fa fa-phone",
              });
            return (
              <ListItem
                name={consultant.atendents_consultants_name}
                description={consultant.atendents_consultants_description}
                imageUrl={consultant.consultant_image_url}
                link={consultant.atendents_consultants_url}
                info={info}
                expanded={props.expanded}
              />
            );
          })
        ) : (
          <div>{ptBR.list.emptyList}</div>
        )
      ) : (
        <div>{ptBR.list.loadingList}...</div>
      )}
    </div>
  );
}

export default ConsultantList;
