import { faCamera, faFlask, faUser } from "@fortawesome/free-solid-svg-icons";
import MenuButton from "./MenuButton";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import ptBR from "../../languages/pt-BR";

function MenuNav(props: {className?: string}) {
  const navigate = useNavigate();
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  function selectImg(event: any) {
    navigate("/camera", { state: { file: event.target.files[0] } });
  }

  return (
    <div className={props.className}>
      <MenuButton
        icon={"fa fa-camera"}
        text={ptBR.menu.identifyPlague}
        onClick={() => hiddenFileInput.current?.click()}
      />
      <MenuButton
        icon={"fa fa-user"}
        text={ptBR.menu.consultants}
        onClick={() => navigate("/consultants")}
      />
      <MenuButton
        icon={"fa fa-flask"}
        text={ptBR.menu.products}
        onClick={() => navigate("/products")}
      />
      <input
        type="file"
        onChange={selectImg}
        style={{ display: "none" }}
        ref={hiddenFileInput}
        accept=".jpg,.jpeg,.png"
      />
    </div>
  );
}

export default MenuNav;
