import "./CameraScreen.scss";
import MainButton from "../commons/MainButton";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import appService from "../../services/appService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ptBR from "../../languages/pt-BR";
import Div100vh from "react-div-100vh";

function CameraScreen() {
  const [url, setUrl] = useState<string | null>(null);
  const [uploadDisabled, setUploadDisabled] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state.file === null) navigate(-1);
    const url = URL.createObjectURL(location.state.file);
    setUrl(url);
  }, []);

  const sendPhoto = async () => {
    if (location.state.file == null) return;

    try {
      setUploadDisabled(true);
      const response = await toast.promise(appService.classifyImage(location.state.file),
      {
        pending: ptBR.camera.classifyImagePending,
        error: ptBR.camera.classifyImageError
      });

      navigate("/classifier", { state: response.data });
    } catch (e: any) {
      toast.error(ptBR.camera.classifyImageError);
      setUploadDisabled(false);
      return;
    }
    setUploadDisabled(false);
  };

  return (
    <Div100vh className="camera-screen">
      <div className="cancel-picture" onClick={() => navigate(-1)}>
        <i className={"fa fa-times cancel-cross"} />
      </div>
      {url && <img src={url} alt="Screenshot" className="plague-img" />}
      <div className="camera-buttons">
        <MainButton onClick={sendPhoto} disable={uploadDisabled}>{ptBR.camera.sendImage}</MainButton>
      </div>
    </Div100vh>
  );
}

export default CameraScreen;
